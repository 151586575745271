import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Animation from "../components/animation"
import ym from "react-yandex-metrika"


class Index extends React.Component {
  componentDidMount() {
    ym("hit", "/")
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Главная - Исполиния</title>

          <meta name="description"
                content="Компания ИСПОЛИНИЯ, официальный дилер RBM в России, предлагает широкий спектр продукции: фильтры линейные, самоочищающиеся, магнитные; редукторы давления, воздухоотводчики, радиаторные клапаны и многое другое в наличии." />

          <meta property="og:title" content="Главная" />
          <meta property="og:description"
                content="Компания ИСПОЛИНИЯ, официальный дилер RBM в России, предлагает широкий спектр продукции: фильтры линейные, самоочищающиеся, магнитные; редукторы давления, воздухоотводчики, радиаторные клапаны и многое другое в наличии." />
        </Helmet>
        <Header />
        <main>
          <Container fluid={true}>
            <Animation />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

export default Index